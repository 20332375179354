import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { getThemeProps } from '@elo-kit/utils/block.utils'
import { withStores } from 'utils/helpers.utils'
import { ascendingSort } from '@elo-kit/utils/helpers.utils'
import { EMAIL_REGEX } from '@elo-kit/constants/regex.constants'

import { FIELD_TYPE_KEYS, INPUT_FIELD_KEYS } from 'shared/components/page-builder/constants/pageBuilder.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { useContactFormStyles } from 'shared/components/content-page/preview/blocks/contact-form/contactFormStyles'
import EditTextArea from 'shared/components/page-builder/page-view/edit-text-area/EditTextArea'
import { LazyBackgroundImage } from '@elo-kit/components/lazy-background-image/LazyBackgroundImage'
import { notify } from 'libs/common/notify'

import ContentBlockDateTimeField from '../../components/fields/date-time-field/ContentBlockDateTimeField'
import ContentBlockTextField from '../../components/fields/text-field/ContentBlockTextField'
import ContentBlockTextareaField from '../../components/fields/textarea-field/ContentBlockTextareaField'
import ContentBlockCountrySelectorField from '../../components/fields/country-selector/ContentBlockCountrySelectorField'

import 'shared/components/page-builder/shared/form/styles/content-block-field.scss'

const propTypes = {
  classes: PropTypes.shape({}),
  block: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  previewMode: PropTypes.bool,
}
const defaultProps = {
  previewMode: false,
}

const getFieldInfo = (fieldForm, fieldType) => {
  let Wrapper = null

  switch (fieldForm) {
    case INPUT_FIELD_KEYS.countryCode: {
      Wrapper = ContentBlockCountrySelectorField
      break
    }
    case INPUT_FIELD_KEYS.birthday: {
      Wrapper = ContentBlockDateTimeField
      break
    }
    default: {
      Wrapper = ContentBlockTextField
    }
  }

  if (fieldType === FIELD_TYPE_KEYS.textarea) {
    Wrapper = ContentBlockTextareaField
  }

  return {
    isCountryField: fieldForm === INPUT_FIELD_KEYS.countryCode,
    isBirthdayField: fieldForm === INPUT_FIELD_KEYS.birthday,
    isTextarea: fieldType === FIELD_TYPE_KEYS.textarea,
    Wrapper,
  }
}

const ContactFormPreview = (props) => {
  const [formFieldsData, setFormFieldsData] = useState({})
  const {
    block,
    block: { contactForm: { id: contactFormId, formFields } = {}, content },
    previewMode,
    countriesStore,
    contentPageStore,
  } = props
  const { buttonText, titleText, descriptionText, buttonAnimation } = content || {}
  const classes = useContactFormStyles({ theme: getThemeProps(block) })
  const updateFormFieldsData = (name, value, label, id) => {
    const updateValue =
      name === INPUT_FIELD_KEYS.custom
        ? {
            custom: {
              ...formFieldsData.custom,
              [id]: {
                label,
                value,
              },
            },
          }
        : { [name]: value }

    const formData = {
      ...formFieldsData,
      ...updateValue,
    }

    setFormFieldsData(formData)
  }

  const submitForm = () => {
    const { username, shopThemeId } = contentPageStore.seller || {}

    contentPageStore
      .createSellerLeads(username, {
        ...formFieldsData,
        contactFormId,
        shopThemeId,
      })
      .then((resp) => {
        if (resp.success) {
          // reset contact form data
          setFormFieldsData({})
          notify('success', I18n.t('react.shared.notific.successfully_submitted'))
        }
      })
  }

  const sortedList = ascendingSort(formFields)
  const requiredFields = sortedList?.filter(({ required }) => required)
  const isExistAllRequiredValue = requiredFields?.map(({ fieldForm }) => formFieldsData[fieldForm]).every(Boolean)
  const isExistCustomRequiredValue = requiredFields
    ?.map(({ id, fieldForm }) => {
      if (fieldForm === INPUT_FIELD_KEYS.custom) {
        if (formFieldsData[fieldForm]) {
          return formFieldsData[fieldForm][id]?.value
        }
        return false
      }

      return true
    })
    .every(Boolean)

  const emailInvalid = formFieldsData?.email && !EMAIL_REGEX.test(formFieldsData?.email)
  const submitDisabled = !isExistAllRequiredValue || emailInvalid || !isExistCustomRequiredValue

  if (!sortedList.length) {
    return null
  }

  return (
    <div className={classNames(classes.container, 'contact-form-container')}>
      <LazyBackgroundImage block={block} isResizable />
      <div className={classes.contactForm}>
        <div className={classes.contactFormTitle}>
          <span
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.contactForm.title,
              'data-highlighter-selector': '',
            })}
          >
            <EditTextArea value={titleText} dataKey='titleText' previewMode={previewMode}>
              {titleText}
            </EditTextArea>
          </span>
        </div>
        <div
          className={classNames(classes.contactFormDescription)}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.contactForm.description,
            'data-highlighter-selector': '',
          })}
        >
          <EditTextArea value={descriptionText} dataKey='descriptionText' previewMode={previewMode}>
            {descriptionText}
          </EditTextArea>
        </div>
        {sortedList?.map((item) => {
          const { id, label, fieldType, fieldForm, required } = item
          const { isCountryField, isTextarea, isBirthdayField, Wrapper } = getFieldInfo(fieldForm, fieldType)
          let fieldProps = {
            contactFormId,
            inputId: id,
          }

          if (isBirthdayField) {
            fieldProps = {
              renderInput: (props) => <input {...props} value={formFieldsData[fieldForm] ? props.value : ''} />,
            }
          }

          if (isCountryField) {
            fieldProps = {
              handleInput: ({ code }) => updateFormFieldsData(fieldForm, code),
              handleValid: () => true,
              countriesList: countriesStore.list,
              fetchList: countriesStore.fetchList,
              key: formFieldsData[fieldForm],
              validations: [],
            }
          }

          const formattedLabel = (
            <div
              className={classNames({
                'field--required': required,
              })}
            >
              <div className='field__label'>
                <span
                  className={classNames(classes.contactFormLabel)}
                  {...(previewMode && {
                    'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.contactForm.labels,
                    'data-highlighter-selector': '.field__label',
                    'data-highlighter-parent': '.contact-form-container',
                  })}
                >
                  {label}
                </span>
              </div>
            </div>
          )

          return (
            <Fragment key={id}>
              {previewMode && formattedLabel}
              {!previewMode && (isCountryField || isBirthdayField) && (
                <div
                  className={classNames({
                    'field--required': required,
                  })}
                >
                  <div className='field__label'>
                    <span className={classes.contactFormLabel}>{label}</span>
                  </div>
                </div>
              )}
              <div
                className={classNames('fields-container content-block-field--wrapper')}
                {...(previewMode && {
                  'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.contactForm.placeholders,
                  'data-highlighter-selector': '.fields-container.content-block-field--wrapper',
                  'data-highlighter-parent': '.contact-form-container',
                })}
              >
                <Wrapper
                  label={
                    !previewMode && !isBirthdayField ? (
                      <div
                        className={classNames(classes.contactFormLabel, {
                          field__label: isTextarea,
                        })}
                      >
                        {label}
                      </div>
                    ) : null
                  }
                  className={classNames('content-block-field--long', {
                    [classes.contactFormCountryField]: isCountryField,
                  })}
                  placeholder={isBirthdayField ? I18n.t('react.shared.date.format') : label}
                  onChange={(value) => updateFormFieldsData(fieldForm, value, label, id)}
                  value={
                    fieldForm === INPUT_FIELD_KEYS.custom
                      ? (formFieldsData[fieldForm] || {})[id]?.value || ''
                      : formFieldsData[fieldForm] || ''
                  }
                  field={{ value: '' }}
                  isInvalid={!previewMode && fieldForm === INPUT_FIELD_KEYS.email ? emailInvalid : false}
                  errorMessage={I18n.t('react.shared.validations.invalid_email')}
                  autocomplete='new-password'
                  required={!previewMode ? required : false}
                  {...fieldProps}
                />
              </div>
            </Fragment>
          )
        })}
        {!!formFields.length && (
          <div className={classes.contactFormButtonContainer}>
            <button
              className={classNames(classes.contactFormButton, {
                [classes[buttonAnimation]]: !!buttonAnimation,
                [classes.contactFormButtonDisabled]: !previewMode ? submitDisabled : false,
              })}
              onClick={!previewMode ? submitForm : null}
              {...(previewMode && {
                'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.contactForm.button,
                'data-highlighter-selector': '',
              })}
            >
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

ContactFormPreview.defaultProps = defaultProps
ContactFormPreview.propTypes = propTypes
ContactFormPreview.displayName = 'ContactFormPreview'

const stores = ['countriesStore', 'contentPageStore']
export default withStores(stores, ContactFormPreview)
