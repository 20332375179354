import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import withStyles, { ThemeProvider } from 'react-jss'
import { observable, runInAction, action, makeObservable } from 'mobx'
import classNames from 'classnames'

import { SCREEN_SIZE } from 'constants/general.constants'
import { DEFAULT_SHOP_THEME_PAGE_IDS, SHOP_PAGE_TYPE } from 'constants/themes.constants'
import { GET } from 'utils/requests.utils'
import { getDefaultShopMainPage, getDefaultShopProductPage, getDefaultShopThankYouPage } from 'utils/themesShared.utils'
import { isStickyHeader } from 'utils/contentBlock.utils'
import { profile } from 'utils/profileHelper.utils'
import { getElopageConfig } from 'utils/elopageConfig.utils'
import { createId } from '@elo-kit/utils/general.utils'

import { EloButton } from '@elo-kit/components/elo-button/EloButton'

import { BLOCKS } from './blocks'
import contentBlockStyles from './contentBlockStyles'

import { SCREENS } from '../../page-builder/constants/pageBuilder.constants'

const DEFAULT_SCOPE = 'cabinet'

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  scope: PropTypes.string,
  showNoContentMsg: PropTypes.bool,
}

const defaultProps = {
  scope: DEFAULT_SCOPE,
  showNoContentMsg: false,
}

const ContentBlock = (props) => {
  const {
    baseURL,
    username,
    classes,
    block,
    countries,
    historyPush,
    urlParams = {},
    queryParams = {},
    LinkComponent,
    pathname = '',
    previewMode,
    contentPageStore,
    userStore,
  } = props
  const contentBlockClassname = classNames('content-block', classes.contentBlock)
  const TagName = BLOCKS[block.form]

  // TODO: replace strings with enum
  const isCoursePreview = pathname.includes('course-preview')
  const fixableAudioPlayer = isCoursePreview || ['shop', 'payer'].includes(profile.profileType)

  const params = {
    ...urlParams,
    ...queryParams,
  }

  let blockProps = {
    key: block.id,
    block,
  }

  switch (block.form) {
    case 'header':
      blockProps = {
        ...blockProps,
        baseURL,
        username,
        shopLink: baseURL,
        contentPageStore,
        userStore,
      }
      break
    case 'footer':
      blockProps = {
        ...blockProps,
        baseURL,
        contentPageStore,
        userStore,
      }
      break
    case 'product':
      blockProps = {
        ...blockProps,
        countries,
        historyPush,
        LinkComponent,
      }
      break
    case 'audio_player':
      blockProps = {
        ...blockProps,
        fixableAudioPlayer,
      }
      break
    case 'membership_products':
      blockProps = {
        ...blockProps,
        historyPush,
        params,
        queryCategoryId: queryParams.group_id,
        LinkComponent,
        previewMode: previewMode,
      }
      break
    case 'seller_products':
      blockProps = {
        ...blockProps,
        historyPush,
        params,
        LinkComponent,
        previewMode: previewMode,
      }
      break
    case 'popular_products':
      blockProps = {
        ...blockProps,
        historyPush,
        params: {
          username: urlParams.username || username,
        },
        LinkComponent,
      }
      break
    case 'button':
      blockProps = {
        ...blockProps,
        historyPush,
        params,
        LinkComponent,
      }
      break
    case 'thank_you_page':
      blockProps = {
        ...blockProps,
        username: urlParams.username || username,
      }
      break
    case 'video':
      blockProps = {
        ...blockProps,
        pathname,
        contentPageStore,
        userStore,
      }
      break
    case 'funnel_product':
      blockProps = {
        ...blockProps,
        pathname,
        contentPageStore,
        userStore,
      }
      break
  }

  return <div className={contentBlockClassname}>{TagName && <TagName {...blockProps} />}</div>
}

const StyledContentBlock = withStyles(contentBlockStyles)(ContentBlock)

// TODO: refactor this component (move audio player logic to block)
@observer
class Preview extends Component {
  constructor(props) {
    super(props)
    makeObservable(this)
    // TODO: SSR - rewrite fetchContentPage and screen_size in isomorphic way with MOBX store without Component state
    const { screenSize = 'desktop' } = props
    this.view = screenSize
    const url = `/${props.scope}/content_pages/${props.id}?screen_size=${screenSize}`
    this.data =
      props.contentPageData && props.contentPageData[url]
        ? {
            ...props.contentPageData[url],
          }
        : {
            contentBlocks: [
              {
                children: [
                  {
                    goods: [],
                  },
                ],
              },
            ],
          }
  }

  @observable data = {}
  @observable view = SCREENS.desktop

  componentDidMount() {
    this.handleResize()
    const isNextApp = getElopageConfig('isNextApp')
    const url = `/${this.props.scope}/content_pages/${this.props.id}?screen_size=${this.props.screenSize}`
    const alreadyLoaded = !!(this.props.contentPageData && this.props.contentPageData[url])

    if (!isNextApp || !alreadyLoaded) {
      this.fetchData()
    }

    window.addEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps) {
    const isNextApp = getElopageConfig('isNextApp')

    if (isNextApp) {
      // TODO: remove after migrate content page data to store
      const url = `/${this.props.scope}/content_pages/${this.props.id}?screen_size=${this.props.screenSize}`
      this.props.contentPageData[url]
      if (this.props.contentPageData[url]?.id && this.props.contentPageData[url].id !== this.data.id) {
        const data = this.props.contentPageData[url]
        this.setData(data)
      }
    } else {
      const { id } = this.props

      if (id && id !== prevProps.id) {
        this.fetchData()
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  @action handleResize = () => {
    const screenWidth = window?.innerWidth || SCREEN_SIZE.desktop
    const mobileOrTablet = screenWidth > SCREEN_SIZE.mobile ? SCREENS.tablet : SCREENS.mobile
    const newView = screenWidth >= SCREEN_SIZE.desktop ? SCREENS.desktop : mobileOrTablet

    if (this.view !== newView) {
      this.view = newView
      this.fetchData()
    }
  }

  @action setData = (data) => {
    this.data = data
  }

  fetchContentPage = () => {
    const { id, scope, themeData, pageType, contentPageData } = this.props
    const url = `/${scope}/content_pages/${id}?screen_size=${this.view}`

    if (contentPageData && contentPageData[url]) {
      // prevent reload data already loaded on SSR
      this.data = contentPageData[url]
      return
    }

    GET(url).then((resp) => {
      const { data = {}, success } = resp
      if (success) {
        runInAction(() => {
          if (pageType && data.contentBlocks && !data.contentBlocks.length) {
            switch (pageType) {
              case SHOP_PAGE_TYPE.main:
                this.data = getDefaultShopMainPage(themeData)
                break
              case SHOP_PAGE_TYPE.product:
                this.data = getDefaultShopProductPage(themeData)
                break
              case SHOP_PAGE_TYPE.thankYou:
                this.data = getDefaultShopThankYouPage(themeData)
                break
              default:
                this.setData(data)
                this.props.contentPageStore.processDataResponse && this.props.contentPageStore.processDataResponse(resp)
            }
          } else {
            this.setData(data)
            this.props.contentPageStore.processDataResponse && this.props.contentPageStore.processDataResponse(resp)
          }
        })
      }
    })
  }

  @action fetchData = () => {
    const { id, themeData } = this.props

    switch (id) {
      case DEFAULT_SHOP_THEME_PAGE_IDS.main:
        this.data = getDefaultShopMainPage(themeData)
        break
      case DEFAULT_SHOP_THEME_PAGE_IDS.product:
        this.data = getDefaultShopProductPage(themeData)
        break
      case DEFAULT_SHOP_THEME_PAGE_IDS.thankYou:
        this.data = getDefaultShopThankYouPage(themeData)
        break
      default:
        this.fetchContentPage()
    }
  }

  render() {
    const { headerHidden, showNoContentMsg, countries, hovered, scope, contentPageStore, userStore } = this.props

    const { contentBlocks } = this.data
    const contentClassname = classNames('content-pagebuilder-view fr-view', {
      'content-pagebuilder-view--no-margin': headerHidden,
    })
    const isGoToPageBuilderButtonVisible =
      scope === DEFAULT_SCOPE &&
      !hovered &&
      (showNoContentMsg || !contentBlocks.length) &&
      !document.location.pathname.includes('course-preview')

    return (
      <div className={contentClassname}>
        {contentBlocks &&
          contentBlocks.map((row, index) => {
            const { children, id } = row
            const childsCount = children.filter(({ content = {} }) => !content[`${this.view}Hidden`]).length
            const contentBlockClasses = classNames(`content-blocks-row childs-count-${childsCount}`, {
              'sticky-header': childsCount === 1 && isStickyHeader(children[0]),
            })
            return (
              <div key={createId(id, index)} className={contentBlockClasses}>
                {children.map((block, index) => (
                  <ThemeProvider key={createId(block.id, index)} theme={block.content || {}}>
                    <StyledContentBlock
                      {...this.props}
                      countries={countries}
                      block={block || {}}
                      contentPageStore={contentPageStore}
                      userStore={userStore}
                    />
                  </ThemeProvider>
                ))}
              </div>
            )
          })}
        {isGoToPageBuilderButtonVisible && (
          <div className='content-page-preview__overlay'>
            <EloButton className='content-page-preview__button' onClick={() => {}} outlined>
              <i className='fas fa-palette' />
              {I18n.t('react.shared.button.go_to_the_pagebuilder')}
            </EloButton>
          </div>
        )}
      </div>
    )
  }
}

Preview.displayName = 'Preview'
Preview.propTypes = propTypes
Preview.defaultProps = defaultProps

export default Preview
