import React from 'react'
import withStyles, { ThemeProvider } from 'react-jss'
import classNames from 'classnames'

import { getThemeProps } from '@elo-kit/utils/block.utils'

import { LazyBackgroundImage } from '@elo-kit/components/lazy-background-image/LazyBackgroundImage'

import gapStyles from './gapStyles'

const GapContainer = ({ classes, block }) => (
  <div className={classNames(classes.container)}>
    <LazyBackgroundImage block={block} isResizable />
  </div>
)

export const GapContainerWithStyles = withStyles(gapStyles)(GapContainer)

const GapPreview = ({ block, previewMode }) => (
  <ThemeProvider theme={getThemeProps(block)}>
    <GapContainerWithStyles block={block} previewMode={previewMode} />
  </ThemeProvider>
)

export default GapPreview
