import queryString from 'query-string'
import { Block, ContentPageParams, ThemeTypes, BlockForm } from 'shared/components/page-builder/types/pb-general'

import { getElopageConfig } from 'utils/elopageConfig.utils'
import { CATEGORY_AREAS, THEME_TYPE } from 'constants/general.constants'
import { ALLOWED_BLOCK_TYPES, REJECTED_BLOCK_TYPES } from '../constants/pageBuilder.constants'
import { COUNTING_DIRECTIONS, LINK_TYPE } from '../constants/block.constants'

export const isBlockContentEmpty = (obj: { list: any }) =>
  Object.keys(obj).length === 1 && Object.keys(obj)[0] === 'list' && obj.list === null && obj.constructor === Object

export const getThemeType = (params: ContentPageParams) => {
  if (params.shop_theme_id) {
    return THEME_TYPE.shop
  }
  if (params.membership_theme_id) {
    return THEME_TYPE.membership
  }
  if (params.lesson_content_id) {
    return THEME_TYPE.course
  }
  if (params.funnel_content_id) {
    return THEME_TYPE.funnel
  }
  if (params.affiliate_content_id) {
    return THEME_TYPE.affiliate
  }

  return THEME_TYPE.shop
}

export const getAllowedBlocks = (theme: ThemeTypes, pageForm: string): string[] => {
  switch (pageForm) {
    case 'product':
      return ALLOWED_BLOCK_TYPES.shopProduct
    case 'thank_you':
      return ALLOWED_BLOCK_TYPES.thankYouPage
    default:
      return ALLOWED_BLOCK_TYPES[theme]
  }
}

export const getCategoriesArea = (type: ThemeTypes, pageForm: string) =>
  pageForm === 'product' ? CATEGORY_AREAS.product : CATEGORY_AREAS[type]

export const getRejectedBlockes = (theme: ThemeTypes, pageForm: string) => {
  switch (pageForm) {
    case 'product':
      return REJECTED_BLOCK_TYPES.shopProduct
    case 'thank_you':
      return REJECTED_BLOCK_TYPES.thankYouPage
    default:
      return REJECTED_BLOCK_TYPES[theme]
  }
}

export const isBlockScalable = (form: BlockForm) => !['picture', 'button'].find((item) => item === form)

export const getMenuItemUrlPlaceholder = (menuItem, themePages) =>
  menuItem.linkType === LINK_TYPE.themePage
    ? (themePages.find((item) => item.id === Number(menuItem.url)) || {}).slug || ''
    : menuItem.url || I18n.t('react.cabinet.create_link')

export const changeCategoryTabInUrl = (
  queryParams: { group_id?: number } = {},
  historyPush: any,
  id: number,
  blockId: number
) => {
  if (id) {
    queryParams[`block_${blockId}_group_id`] = id
  } else {
    delete queryParams[`block_${blockId}_group_id`]
  }
  const newQueryParams = queryString.stringify(queryParams)
  const isNextApp = getElopageConfig('isNextApp')

  if (historyPush) {
    if (isNextApp) {
      historyPush({ search: newQueryParams }, undefined, { scroll: false, shallow: true })
    } else {
      historyPush({ search: newQueryParams })
    }
  }
}

export const counterMathFunction = (x: number, y: number, direction: COUNTING_DIRECTIONS = COUNTING_DIRECTIONS.up) => {
  switch (direction) {
    case COUNTING_DIRECTIONS.up: {
      return Number(x) + Number(y)
    }

    case COUNTING_DIRECTIONS.down: {
      const value = Number(x) - Number(y)
      return Math.sign(value) === -1 ? 0 : value
    }

    default: {
      return null
    }
  }
}

export const filterBlocksByResolution = (blocks: Block[], resolution: 'mobile' | 'tablet' | 'desktop' | 'all') =>
  blocks.map((row) => row.children.filter(({ content = {} }) => !content[`${resolution}Hidden`]))
