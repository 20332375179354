import React from 'react'

import { EloInfoIcon } from '@elo-ui/components/icons/regular'
import { EloInfoMessage } from '@elo-ui/components/elo-info-message'

import './renaming-email-warning.scss'

export const RenamingEmailWarning: React.FC = () => (
  <div className='renaming-email-warning'>
    <EloInfoMessage icon={<EloInfoIcon />} size='small' status='information'>
      <div
        dangerouslySetInnerHTML={{
          __html: I18n.t('react.shared.payment.rename_warning', {
            email: `<span class='renaming-email-warning__email'>no-reply@myablefy.com</span>`,
          }),
        }}
      ></div>
    </EloInfoMessage>
  </div>
)
